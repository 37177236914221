"use client";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { SidebarContext } from "../../../context/SidebarContext";
import { RedirectProvider } from "../../../context/RedirectContext";
import { MuteProvider } from "@/src/context/MuteContext";
import { Header } from "@/src/components/common/header/header";
import Footer from "@/src/components/common/footer/footer";
import Sidebar from "../../common/header/Sidebar";
import MobileBottomNav from "../../common/header/mobile-bottom-nav";
import { getLatestChat } from "@/src/apis/api";
import { useGetCountryCode } from "@/src/hooks/query/pricing.query";
import { usePathname, useRouter } from "next/navigation";
import Image from "next/image";
import { Loader2 } from "lucide-react";

// Separate loading component for better code organization
const LoadingScreen = () => (
  <div className="flex flex-col gap-2 h-screen w-screen items-center justify-center">
    <Image
      src="/assets/images/logo.png"
      alt="FulLogo_Transparent"
      width={154}
      height={48}
      priority
    />
    <Loader2 className="animate-spin text-neutral-400" />
  </div>
);

const DefaultLayout = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { sidebarVisible } = useContext(SidebarContext);
  const { data: country, isLoading } = useGetCountryCode();

  // State management
  const [loggedIn, setLoggedIn] = useState(false);
  const [showHideDialog, setShowHideDialog] = useState(false);
  const [lastActiveAvatar, setLastActiveAvatar] = useState("");

  // Check user authentication
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      setLoggedIn(true);
    }
  }, []);

  // Fetch latest chat data
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (!user_id) return;

    const fetchLatestChat = async () => {
      try {
        const latestUser = await getLatestChat({ user_id });
        const username = latestUser.data.data?.username;
        if (username) {
          setLastActiveAvatar(username);
        }
      } catch (error) {
        console.error("Error fetching latest chat:", error);
      }
    };

    fetchLatestChat();
  }, []);

  // Handle India-specific redirect
  useEffect(() => {
    const hasOpened = localStorage.getItem("opened") === "true";
    if (!hasOpened && country === "IN" && pathname === "/") {
      localStorage.setItem("opened", "true");
      router.push("/explore?country=india");
    }
  }, [country, pathname, router]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const mainContentClass = `flex-grow ${
    loggedIn ? "pt-[60px]" : "pt-[65px] md:pt-[70px]"
  } ${sidebarVisible ? "sidebar-open-class" : "sidebar-closed-class"}`;

  const sidebarClass = sidebarVisible ? "lg:ps-[65px]" : "lg:ps-[65px]";

  const dialogProps = {
    showHideDialog,
    setShowHideDialog,
  };

  return (
    <RedirectProvider>
      <div className="relative flex flex-col min-h-screen">
        <Header {...dialogProps} />

        <Sidebar lastActiveAvatar={lastActiveAvatar} {...dialogProps} />

        <div className={mainContentClass}>
          <div className={sidebarClass}>
            <MuteProvider>{children}</MuteProvider>
          </div>
        </div>

        <Footer />

        <MobileBottomNav
          isLoggedIn={loggedIn}
          lastActiveAvatar={lastActiveAvatar}
          {...dialogProps}
        />
      </div>
    </RedirectProvider>
  );
};

export default DefaultLayout;

"use client"

import Link from "next/link"
import { usePathname } from "next/navigation"
import {
  Home,
  MessageCircle,
  Settings,
  Tag,
  Terminal,
  UserPen,
} from "lucide-react"
import { useContext, useEffect, useState } from "react"
import { set } from "hume/core/schemas"
import { RedirectContext } from "@/src/context/RedirectContext"
import { IoSearch } from "react-icons/io5"

export default function MobileBottomNav({
  isLoggedIn,
  lastActiveAvatar,
  showHideDialog,
  setShowHideDialog,
}) {
  const pathname = usePathname()
  const { redirectContext, setRedirectContext } = useContext(RedirectContext)

  return (
    <nav className="z-[2000] fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 dark:bg-gray-900 dark:border-gray-700 lg:hidden">
      <div className="flex justify-around items-center h-14">
        <span onClick={() => setRedirectContext("/explore")}>
          <NavItem
            href="/explore"
            icon={<IoSearch size={20} />}
            label="Explore"
            active={pathname.includes("explore")}
          />
        </span>
        <span
          onClick={
            !isLoggedIn
              ? () => {
                  setShowHideDialog(true)
                  setRedirectContext(`/chat/${lastActiveAvatar}`)
                }
              : () => {}
          }
        >
          <NavItem
            href={isLoggedIn ? `/chat/${lastActiveAvatar}` : "#"}
            icon={<MessageCircle size={20} />}
            label="Chat"
            active={pathname.includes("chat")}
          />
        </span>
        {/* <span
          onClick={
            !isLoggedIn
              ? () => {
                  setShowHideDialog(true)
                  setRedirectContext("/create")
                }
              : () => {}
          }
        >
          <NavItem
            href={isLoggedIn ? `/create` : "#"}
            icon={<UserPen size={20} />}
            label="Create"
            active={pathname.includes("create")}
          />
        </span> */}
        <span onClick={() => setRedirectContext("/pricing")}>
          <NavItem
            href="/pricing"
            icon={<Tag size={20} />}
            label="Pricing"
            active={pathname === "/pricing"}
          />
        </span>
        <span
          onClick={
            !isLoggedIn
              ? () => {
                  setShowHideDialog(true)
                  setRedirectContext("/usage")
                }
              : () => {}
          }
        >
          <NavItem
            href={isLoggedIn ? "/usage" : "#"}
            icon={<Settings size={20} />}
            label="Usage"
            active={pathname === "/usage"}
          />
        </span>
      </div>
    </nav>
  )
}

function NavItem({ href, icon, label, active }) {
  return (
    <Link
      href={href}
      className={`flex flex-col items-center justify-center w-full h-full ${
        active ? "text-pink-500" : "text-gray-500 dark:text-gray-400"
      }`}
    >
      {icon}
      <span className="text-[10px] mt-0.5">{label}</span>
    </Link>
  )
}

"use client";

import Image from "next/image";
import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import PopupContainer from "../../common/popup-container";
import { useRouter } from "next/navigation";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { googleLogin } from "@/src/apis/api";
import { RedirectContext } from "@/src/context/RedirectContext";

// Whitelisted emails
const whiteListedEmails = ['mailbox@phanig.com', 'srikant@redmattertech.com', 'tsandeep86@gmail.com', 'nhabtamu42@gmail.com', 'alakhireddy@gmail.com', "soubhagyakumar666@gmail.com", "nardos1g3@gmail.com", "nahomhabtamu42@gmail.com", "contact@phanig.com"]

const DiscoverDialog = ({ setShowHideDialog }) => {
  const router = useRouter();
  const [googleUser, setGoogleUser] = useState(null);
  let { redirectContext } = useContext(RedirectContext);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse.access_token);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handlePayload = async (payload) => {
    try {
      const res = await googleLogin(payload);
      
      // Limit login access on dev.avatarx.live to only whitelisted emails
      if (window.location.origin.toString().includes('dev.avatarx.live')) {
        if (!whiteListedEmails.includes(res.data.data.email)) {
          alert('You are not authorized to access this page')
          setGoogleUser(null)
          return
        }
      }

      if (res.data) {
        localStorage.setItem("user_id", res.data.data.id);
        localStorage.setItem("user_email", res.data.data.email);
        localStorage.setItem("user_first_name", res.data.data.first_name);
        localStorage.setItem("user_last_name", res.data.data.last_name);

        if (redirectContext)
          window.location.href = window.location.origin + redirectContext
        else
          window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // setEmailNotExists(true);
    }
  };

  useEffect(() => {
    if (googleUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          handlePayload({
            email: res.data.email,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            password: res.data.id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [googleUser]);

  return (
    <>
      <PopupContainer>
        <button
          type='button'
          onClick={() => setShowHideDialog(false)}
          className='size-6 flex absolute right-8 top-7 justify-center items-center'
        >
          <img src={`/assets/svg/Close.svg`} alt='' width={15} height={15} />
        </button>
        <div className='px-2 py-5 text-center'>
          {/* <img
            src={`/assets/images/logo_icon.png`}
            alt=''
            width={50}
            height={50}
            className='mx-auto my-4'
          /> */}
          <img
            src='/assets/images/logo.png'
            alt='avatar X'
            width={120}
            height={50}
            className='mx-auto my-4'
          />
          <h2 className='text-2xl font-bold text-indigo-950 tracking-wide text-center mb-4'>
            Create an account or Sign in
          </h2>
          <p className='text-sm'>
            New users get 100 free credits for Avatar creation, image and video
            generation.
          </p>
        </div>
        <button
          type='button'
          className='w-full border-none outline-none h-12 px-2.5 py-1.5 bg-zinc-100 rounded-full border mt-8 border-zinc-100 justify-center items-center gap-2.5 inline-flex'
          onClick={() => login()}
        >
          <div className='justify-center items-center gap-2 flex'>
            <div className='w-6 h-6 relative'>
              <img
                src={`/assets/svg/google.svg`}
                alt=''
                width={26}
                height={26}
              />
            </div>
            <div className='text-indigo-950 text-base font-normal'>
              Continue with Google
            </div>
          </div>
        </button>
        <div className='flex justify-center items-center pt-16'>
          <p className='text-sm font-medium text-center text-indigo-950'>
            By continuing, you agree to the
            <strong
              onClick={() => {
                router.push("/terms");
                setShowHideDialog(false);
              }}
              className='text-indigo-950 cursor-pointer underline font-bold'
            >
              {" "}
              Terms of Service
            </strong>{" "}
            and
            <Link
              href={`/privacy`}
              className='text-indigo-950 font-bold underline'
            >
              {" "}
              Privacy Policy
            </Link>
          </p>
        </div>
      </PopupContainer>
    </>
  );
};

const OpenEmailDialog = ({ setShowEmailDialog }) => {
  return (
    <>
      <PopupContainer>
        <button
          type='button'
          onClick={() => setShowEmailDialog(false)}
          className='size-6 flex absolute right-8 top-7 justify-center items-center'
        >
          <img src={`/assets/svg/Close.svg`} alt='' width={15} height={15} />
        </button>
        <div className='flex px-5 flex-col gap-32 pb-20 items-center'>
          <img
            src={`/assets/images/logo_icon.png`}
            alt=''
            width={50}
            height={50}
          />
          <div className='flex flex-col items-center gap-4'>
            <img
              src={`/assets/svg/SMS 1.svg`}
              alt=''
              width={30}
              height={30}
            />
            <h2 className='w-full text-center text-indigo-950 text-[23px] font-semibold'>
              Check your email
            </h2>
            <div className='w-[347px] text-center text-indigo-950 text-base font-medium'>
              We sent an email to the address you provided. Tap the link inside
              the email to continue.
            </div>
            <div className='flex items-center gap-1'>
              <span className='text-indigo-950 text-[13px] font-medium'>
                Wrong email?{" "}
              </span>
              <span className='text-pink-400 text-[13px] cursor-pointer font-medium underline'>
                Go back
              </span>
              <span className='text-indigo-950 text-[13px] font-medium'>
                {" "}
                again to continue
              </span>
            </div>
          </div>
        </div>
      </PopupContainer>
    </>
  );
};

export { DiscoverDialog, OpenEmailDialog };

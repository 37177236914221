import React, { createContext, useContext, useState } from "react";

// Create the Mute Context
const MuteContext = createContext();

// Provider component to wrap around the part of your app where you need shared state
export const MuteProvider = ({ children }) => {
  const [isMuted, setIsMuted] = useState(true);

  // Toggle mute state
  const toggleMute = () => setIsMuted((prev) => !prev);

  return (
    <MuteContext.Provider value={{ isMuted, toggleMute }}>
      {children}
    </MuteContext.Provider>
  );
};

// Custom hook to use the Mute Context
export const useMute = () => {
  const ctx = useContext(MuteContext);
  if (!ctx) throw new Error("useMute should be used inside MuteProvider");
  return ctx;
};

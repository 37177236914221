import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleOAuthProvider"] */ "/home/kats-frontend/node_modules/@react-oauth/google/dist/index.esm.js");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/kats-frontend/node_modules/react-multi-carousel/lib/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kats-frontend/src/components/shared/DefaultLayout/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/kats-frontend/src/context/DarkmodeContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderCreditContextProvider"] */ "/home/kats-frontend/src/context/HeaderCreditContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kats-frontend/src/context/QueryProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/home/kats-frontend/src/context/SidebarContext.js");

"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Wrapper from "../Wrapper";
import { useContext } from "react";
import { SidebarContext } from "../../../context/SidebarContext";
import { DarkmodeContext } from "../../../../src/context/DarkmodeContext";


export default function Footer() {
  const { sidebarVisible } = useContext(SidebarContext);
  const { theme, toggleTheme } = useContext(DarkmodeContext); 
  const logoSrc = theme === 'light' ? '/assets/images/logo.png' : '/assets/images/white_logo.png';


  return (
    <div
      className={`border-t border-gray-300 bg-white md:pt-16 pt-8 md:pb-24 pb-12 md:mt-20 dark:bg-gray-900 dark:text-white ${
        sidebarVisible ? "lg:ps-[65px]" : "lg:ps-[65px]"
      }`}
    >
      <div className='px-3'>
        <Wrapper>
          <div className='md:flex flex-wrap'>
            <div className='md:w-1/3'>
              <img
                src={logoSrc}
                alt='avatar X'
                width={120}
                height={50}
                className='mb-7'
              />
              <p className='text-sm leading-6'>
                AvatarX is an AI chatbot platform designed for immersive
                experiences. It allows users to create and interact with AI
                companions for roleplay and other activities, while also
                enabling the generation of realistic images, videos to enhance
                the experience.
              </p>
            </div>
            <div className='md:ml-auto md:w-1/4 mt-7 md:mt-0'>
              <h3 className='font-bold text-lg mb-5'>Social</h3>
              <div className='space-y-4 flex flex-col'>
                <div>
                  <Link
                    href='https://discord.com/invite/ttkXhDPf6u'
                    target='_blank'
                    className='flex space-x-2 hover:underline items-center dark:text-white '
                  >
                    <svg
                      className="dark:fill-white"
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='25'
                      height='25'
                      viewBox='0 0 30 30'
                    >
                      <path d='M25.12,6.946c-2.424-1.948-6.257-2.278-6.419-2.292c-0.256-0.022-0.499,0.123-0.604,0.357 c-0.004,0.008-0.218,0.629-0.425,1.228c2.817,0.493,4.731,1.587,4.833,1.647c0.478,0.278,0.638,0.891,0.359,1.368 C22.679,9.572,22.344,9.75,22,9.75c-0.171,0-0.343-0.043-0.501-0.135C21.471,9.598,18.663,8,15.002,8 C11.34,8,8.531,9.599,8.503,9.615C8.026,9.892,7.414,9.729,7.137,9.251C6.86,8.775,7.021,8.164,7.497,7.886 c0.102-0.06,2.023-1.158,4.848-1.65c-0.218-0.606-0.438-1.217-0.442-1.225c-0.105-0.235-0.348-0.383-0.604-0.357 c-0.162,0.013-3.995,0.343-6.451,2.318C3.564,8.158,1,15.092,1,21.087c0,0.106,0.027,0.209,0.08,0.301 c1.771,3.11,6.599,3.924,7.699,3.959c0.007,0.001,0.013,0.001,0.019,0.001c0.194,0,0.377-0.093,0.492-0.25l1.19-1.612 c-2.61-0.629-3.99-1.618-4.073-1.679c-0.444-0.327-0.54-0.953-0.213-1.398c0.326-0.443,0.95-0.541,1.394-0.216 C7.625,20.217,10.172,22,15,22c4.847,0,7.387-1.79,7.412-1.808c0.444-0.322,1.07-0.225,1.395,0.221 c0.324,0.444,0.23,1.066-0.212,1.392c-0.083,0.061-1.456,1.048-4.06,1.677l1.175,1.615c0.115,0.158,0.298,0.25,0.492,0.25 c0.007,0,0.013,0,0.019-0.001c1.101-0.035,5.929-0.849,7.699-3.959c0.053-0.092,0.08-0.195,0.08-0.301 C29,15.092,26.436,8.158,25.12,6.946z M11,19c-1.105,0-2-1.119-2-2.5S9.895,14,11,14s2,1.119,2,2.5S12.105,19,11,19z M19,19 c-1.105,0-2-1.119-2-2.5s0.895-2.5,2-2.5s2,1.119,2,2.5S20.105,19,19,19z'></path>
                    </svg>
                    <div className='text-sm'>
                      AvatarX on <span className='opacity-60'>Discord</span>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href='https://x.com/AvatarXlive'
                    target='_blank'
                    className='flex space-x-2 hover:underline items-center'
                  >
                    <svg
                      className="dark:fill-white"
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='25'
                      height='25'
                      viewBox='0 0 30 30'
                    >
                      <path d='M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z'></path>
                    </svg>
                    <div className='text-sm'>
                      AvatarX on <span className='opacity-60'>Twitter</span>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href='https://www.instagram.com/avatarxlive/'
                    target='_blank'
                    className='flex space-x-2 hover:underline items-center'
                  >
                    <svg
                      className="dark:fill-white"
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='25'
                      height='25'
                      viewBox='0 0 30 30'
                    >
                      <path d='M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z'></path>
                    </svg>
                    <div className='text-sm'>
                      AvatarX on <span className='opacity-60'>Instagram</span>
                    </div>
                  </Link>
                </div>

                <div>
                  <Link
                    href='https://www.tiktok.com/@avatarxlive'
                    target='_blank'
                    className='flex space-x-2 hover:underline items-center'
                  >
                    <svg
                      className="dark:fill-white"
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='25'
                      height='25'
                      viewBox='0 0 30 30'
                    >
                      <path d='M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z'></path>
                    </svg>
                    <div className='text-sm'>
                      AvatarX on <span className='opacity-60'>Tiktok</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <p className='text-sm mt-10'>
            © 2024 AvatarX Inc. All Rights Reserved
          </p>
          <div className='flex space-x-4 mt-2 text-xs opacity-60'>
            <Link href='/privacy' className='hover:underline'>
              Privacy Policy
            </Link>
            <Link href='/terms' className='hover:underline'>
              Terms of conditions
            </Link>
          </div>
        </Wrapper>
      </div>
    </div>
  );
}

"use client";

import React, { useState } from 'react'
import PopupContainer from '../../common/popup-container'
import Image from 'next/image'
import Radio from '../../common/radio/radio';


export default function BillingMethodDialog({ setBillingMethodDialog }) {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [addBillingMethodData, setaddBillingMethodData] = useState(false);

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const options = [
        { value: 'option1', label: 'MasterCard ending in 4458 ', billing: `/assets/svg/mater-card.svg` },
        { value: 'option2', label: 'VisaCard ending in 4442 ', billing: `/assets/svg/visa-card.svg` }
    ];
    return (
        <>
            <PopupContainer>
                <button type="button" onClick={() => setBillingMethodDialog(false)} className='size-6 flex absolute right-8 top-7 justify-center items-center'>
                    <img src={`/assets/svg/Close.svg`} alt='' width={15} height={15} />
                </button>
                <div className='flex justify-center pt-8'>
                    <div className="w-fit h-[30px] px-4 py-1 bg-zinc-300 rounded-full justify-center items-center gap-2.5 inline-flex">
                        <p className='text-base text-indigo-950 font-normal'>
                            Balance : <b>0 coins</b>
                        </p>
                    </div>
                </div>
                {addBillingMethodData ? (
                    <>
                        <div className='flex justify-start pt-7'>
                            <h5 className="text-indigo-950 text-xl font-semibold leading-normal">Add a billing method</h5>
                        </div>
                        <div className='space-y-3 pt-4'>
                            <div className="w-full h-11 px-5 py-1.5 rounded-full border border-black/20 justify-start items-center gap-2.5 inline-flex">
                                <input className="text-slate-800/30 w-full border-none outline-none bg-transparent text-base font-normal leading-9" placeholder='1234 5678 9123 4567' />
                            </div>
                            <div className="w-full h-11 px-5 py-1.5 rounded-full border border-black/20 justify-start items-center gap-2.5 inline-flex">
                                <input className="text-slate-800/30 w-full border-none outline-none bg-transparent text-base font-normal leading-9" placeholder='Name on card' />
                            </div>
                            <div className="w-full h-11 px-5 py-1.5 rounded-full border border-black/20 justify-start items-center gap-2.5 inline-flex">
                                <div className="w-6 h-6 px-[1.25px] py-[3.25px] justify-center items-center flex">
                                    <img src={`/assets/svg/Calender 1.svg`} alt='' width={20} height={20} />
                                </div>
                                <input className="text-slate-800/30 w-full border-none outline-none bg-transparent text-base font-normal leading-9" placeholder='Expiry Date(MM/YY)' />
                            </div>
                            <div className="w-full h-11 px-5 py-1.5 rounded-full border border-black/20 justify-start items-center gap-2.5 inline-flex">
                                <div className="w-6 h-6 px-[1.25px] py-[3.25px] justify-center items-center flex">
                                    <img src={`/assets/svg/Lock 2.svg`} alt='' width={20} height={20} />
                                </div>
                                <input className="text-slate-800/30 w-full border-none outline-none bg-transparent text-base font-normal leading-9" placeholder='cvc' />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='flex justify-start pt-7'>
                            <h5 className="text-indigo-950 text-xl font-semibold leading-normal">Select billing method</h5>
                        </div>

                        <Radio
                            options={options}
                            name="customRadio"
                            selectedOption={selectedOption}
                            onChange={handleRadioChange}
                        />
                        <button type="button" className="w-fit mt-7 h-[33px] px-4 py-3 rounded-full border text-center text-pink-500 text-xs font-normal border-pink-500 justify-center items-center gap-2.5 inline-flex">
                            Add new billing methods
                        </button>
                        <div className='flex justify-center pt-7'>
                            <button type='button' onClick={() => setaddBillingMethodData(true)} className="w-fit shadow__lg h-11 mt-7 px-8 py-2 text-white text-base font-medium bg-gradient-to-b from-neutral-400 to-pink-500 rounded-[39px] shadow justify-center items-center gap-[5.42px] inline-flex">
                                Buy($8.99)
                            </button>
                        </div>
                    </>
                )}


            </PopupContainer>
        </>
    )
}

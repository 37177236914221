"use client"

import React, { useEffect, useState, useContext } from "react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import {
  IoPricetagsOutline,
  IoSearch,
  IoSearchOutline,
  IoSettingsOutline,
} from "react-icons/io5"
import { SidebarContext } from "@/src/context/SidebarContext"
import { DarkmodeContext } from "@/src/context/DarkmodeContext"
import { fetchCoins } from "@/src/apis/api"
import { MessageCircle, Terminal, UserPen } from "lucide-react"
import { RedirectContext } from "@/src/context/RedirectContext"

// const LinkLi = ({ label, href, type, onClick }) => {
//   if (type === "button") {
//     return (
//       <li>
//         <button onClick={onClick} className="flex items-center space-x-1 group">
//           <span>{label}</span>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={1.5}
//             stroke="currentColor"
//             className="size-5 group-hover:translate-x-2 transition relative top-0.5"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="m8.25 4.5 7.5 7.5-7.5 7.5"
//             />
//           </svg>
//         </button>
//       </li>
//     );
//   }
//   return (
//     <li>
//       <Link href={href} className="flex items-center space-x-1 group">
//         <span>{label}</span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="size-5 group-hover:translate-x-2 transition relative top-[1px]"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="m8.25 4.5 7.5 7.5-7.5 7.5"
//           />
//         </svg>
//       </Link>
//     </li>
//   );
// };

export default function Sidebar({
  lastActiveAvatar,
  showHideDialog,
  setShowHideDialog,
}) {
  const { sidebarVisible, setSidebarVisible, toggleSidebar } =
    useContext(SidebarContext)
  const { redirectContext, setRedirectContext } = useContext(RedirectContext)
  const { theme, toggleTheme } = useContext(DarkmodeContext)
  const [loggedIn, setLoggedIn] = useState(false)
  const [availableCoins, setAvailableCoins] = useState(0)

  const getCoins = async () => {
    const response = await fetchCoins({
      user_id: localStorage.getItem("user_id"),
    })
    if (response.data) {
      setAvailableCoins(response.data.data.coin + response.data.data.purchased)
    }
  }

  useEffect(() => {
    const user_id = localStorage.getItem("user_id")
    if (user_id) {
      setLoggedIn(true)
    }
  }, [])

  useEffect(() => {
    const user_id = localStorage.getItem("user_id")
    if (user_id) {
      getCoins()
    }
  }, [])

  const handleSidebarToggle = () => {
    if (window.innerWidth <= 768) {
      toggleSidebar()
      return
    }
    setSidebarVisible(false)
  }

  const isVisible = sidebarVisible ? "opacity-100" : "opacity-0"

  return (
    <div
      onMouseEnter={() => {
        setSidebarVisible(true)
      }}
      onMouseLeave={() => setSidebarVisible(false)}
      className={`fixed top-0 pt-[60px] min-h-full h-auto flex flex-col bg-white transition-all duration-300 shadow z-[1900] overflow-auto overflow-x-hidden dark:bg-gray-900 dark:text-white	 ${
        sidebarVisible ? "lg:w-64 w-full" : "w-0 lg:w-16"
      }`}
      data-id="sidebar-panel"
    >
      <ul className="flex flex-col justify-between font-bold">
        <div className="flex flex-col space-y-8 font-bold py-5 px-5">
          <li>
            <Link
              onClick={() => {
                handleSidebarToggle()
                setRedirectContext("/explore")
              }}
              href="/explore"
              className="hover:text-pink-500 block transition relative pl-8 mt-2"
            >
              <IoSearch className="absolute -top-0.1 left-0" size={21} />
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Explore
              </div>
            </Link>
          </li>
          <li>
            <Link
              href={loggedIn ? `/chat/${lastActiveAvatar}` : "#"}
              onClick={
                loggedIn
                  ? () => handleSidebarToggle()
                  : (e) => {
                      e.preventDefault()
                      setRedirectContext(`/chat/${lastActiveAvatar}`)
                      setShowHideDialog(true)
                    }
              }
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <MessageCircle size={20} className="absolute -top-0.5 left-0" />
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Chat
              </div>
            </Link>
          </li>
          {/* <li>
            <Link
              onClick={
                loggedIn
                  ? () => handleSidebarToggle()
                  : (e) => {
                      e.preventDefault();
                      setRedirectContext("/create");
                      setShowHideDialog(true);
                    }
              }
              href={loggedIn ? `/create` : "#"}
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <UserPen size={20} className="absolute -top-0.5 left-0" />
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Create
              </div>
            </Link>
          </li> */}
          <li>
            <Link
              onClick={() => {
                handleSidebarToggle()
                setRedirectContext("/pricing")
              }}
              href="/pricing"
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <IoPricetagsOutline
                size={20}
                className="absolute -top-0.5 left-0"
              />
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Pricing
              </div>
            </Link>
          </li>
          <li>
            <Link
              onClick={
                loggedIn
                  ? () => handleSidebarToggle()
                  : (e) => {
                      e.preventDefault()
                      setRedirectContext("/usage")
                      setShowHideDialog(true)
                    }
              }
              href={loggedIn ? "/usage" : "#"}
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <IoSettingsOutline
                size={20}
                className="absolute -top-0.5 left-0"
              />
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Usage
              </div>
            </Link>
          </li>
        </div>
      </ul>
      <div className="mt-auto py-5 px-5 flex flex-col space-y-5">
        <div className="mt-auto border-t border-gray-300 py-5 flex flex-col space-y-5">
          <div>
            <Link
              href="https://discord.com/invite/ttkXhDPf6u"
              target="_blank"
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25"
                height="25"
                viewBox="0 0 30 30"
                className="absolute -top-0.5 left-0"
              >
                <path
                  fill="currentColor"
                  d="M25.12,6.946c-2.424-1.948-6.257-2.278-6.419-2.292c-0.256-0.022-0.499,0.123-0.604,0.357 c-0.004,0.008-0.218,0.629-0.425,1.228c2.817,0.493,4.731,1.587,4.833,1.647c0.478,0.278,0.638,0.891,0.359,1.368 C22.679,9.572,22.344,9.75,22,9.75c-0.171,0-0.343-0.043-0.501-0.135C21.471,9.598,18.663,8,15.002,8 C11.34,8,8.531,9.599,8.503,9.615C8.026,9.892,7.414,9.729,7.137,9.251C6.86,8.775,7.021,8.164,7.497,7.886 c0.102-0.06,2.023-1.158,4.848-1.65c-0.218-0.606-0.438-1.217-0.442-1.225c-0.105-0.235-0.348-0.383-0.604-0.357 c-0.162,0.013-3.995,0.343-6.451,2.318C3.564,8.158,1,15.092,1,21.087c0,0.106,0.027,0.209,0.08,0.301 c1.771,3.11,6.599,3.924,7.699,3.959c0.007,0.001,0.013,0.001,0.019,0.001c0.194,0,0.377-0.093,0.492-0.25l1.19-1.612 c-2.61-0.629-3.99-1.618-4.073-1.679c-0.444-0.327-0.54-0.953-0.213-1.398c0.326-0.443,0.95-0.541,1.394-0.216 C7.625,20.217,10.172,22,15,22c4.847,0,7.387-1.79,7.412-1.808c0.444-0.322,1.07-0.225,1.395,0.221 c0.324,0.444,0.23,1.066-0.212,1.392c-0.083,0.061-1.456,1.048-4.06,1.677l1.175,1.615c0.115,0.158,0.298,0.25,0.492,0.25 c0.007,0,0.013,0,0.019-0.001c1.101-0.035,5.929-0.849,7.699-3.959c0.053-0.092,0.08-0.195,0.08-0.301 C29,15.092,26.436,8.158,25.12,6.946z M11,19c-1.105,0-2-1.119-2-2.5S9.895,14,11,14s2,1.119,2,2.5S12.105,19,11,19z M19,19 c-1.105,0-2-1.119-2-2.5s0.895-2.5,2-2.5s2,1.119,2,2.5S20.105,19,19,19z"
                ></path>
              </svg>
              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Join us on Discord
              </div>
            </Link>
          </div>
          <div>
            <Link
              href="/contact"
              className="hover:text-pink-500 block transition relative pl-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 absolute -top-0.5 left-0"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>

              <div
                className={`text-sm font-bold text-nowrap transition-all ${isVisible}`}
              >
                Contact us
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import Image from 'next/image';
import React from 'react';

const Radio = ({ options, name, selectedOption, onChange }) => {
    return (
        <div className="space-y-8 pt-7">
            {options.map((option) => (
                <label key={option.value} className="flex items-center cursor-pointer">
                    <input
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={onChange}
                        className="hidden"
                    />
                    <div
                        className={`size-6 mr-2 rounded-full border-2 flex items-center justify-center`}
                    >
                        {selectedOption === option.value && (
                            <div className="size-4 bg-gradient-to-b from-neutral-400 to-pink-500 rounded-full"></div>
                        )}
                    </div>
                    <img src={option.billing} alt='' className='mr-2' width={34} height={24} />
                    <span className='text-indigo-950 font-medium text-base'>{option.label}</span>
                </label>
            ))}
        </div>
    );
};

export default Radio;

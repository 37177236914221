"use client"

import { createContext, useState } from 'react';

export const RedirectContext = createContext();

export function RedirectProvider({ children }) {
  const [redirectContext, setRedirectContext] = useState(null);

  return (
    <RedirectContext.Provider value={{ redirectContext, setRedirectContext }}>
      {children}
    </RedirectContext.Provider>
  );
}
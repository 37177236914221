"use client"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import {
  DiscoverDialog,
  OpenEmailDialog,
} from "../../shared/dialog/discover-dialog"
import Link from "next/link"
import BuyCoinDialog from "../../shared/dialog/buy-coin-dialog"
import BillingMethodDialog from "../../shared/dialog/billing-method-dialog"
import { usePathname } from "next/navigation"
import Sidebar from "./Sidebar"
import { datadogRum } from "@datadog/browser-rum"
import { SidebarContext } from "../../../../src/context/SidebarContext"
import { DarkmodeContext } from "../../../../src/context/DarkmodeContext"
import { useContext } from "react"
import { useRouter } from "next/navigation"
import { fetchCoins } from "@/src/apis/api"
import { HeaderCreditContext } from "@/src/context/HeaderCreditContext"

const Header = () => {
  const { theme, toggleTheme } = useContext(DarkmodeContext)
  const [showHideDialog, setShowHideDialog] = useState(false)
  const [showEmailDialog, setShowEmailDialog] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [buyCoinDialog, setBuyCoinDialog] = useState(false)
  const [billingMethodDialog, setBillingMethodDialog] = useState(false)
  const [profileDropDownMenu, setProfileDropDownMenu] = useState(false)
  // const [sidebarVisible, setSidebarVisible] = useState(false)
  const { sidebarVisible, setSidebarVisible, toggleSidebar } =
    useContext(SidebarContext)
  const { headerCredit, setHeaderCredit } = useContext(HeaderCreditContext)
  const [availableCoins, setAvailableCoins] = useState(0)
  const [isBuyCoinDialgOpen, setBuyCoinDialogOpen] = useState(false)
  const router = useRouter()

  const pathname = usePathname()

  const logoSrc =
    theme === "dark"
      ? "/assets/images/white_logo.png"
      : "/assets/images/logo.png"
  useEffect(() => {
    const user_id = localStorage.getItem("user_id")
    if (user_id) {
      setLoggedIn(true)
    }
  }, [])

  const getCoins = async () => {
    const response = await fetchCoins({
      user_id: localStorage.getItem("user_id"),
    })
    if (response.data) {
      setHeaderCredit(response.data.data.coin + response.data.data.purchased)
    }
  }

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      getCoins();
    }
  
    // log only to prod
    if (window.location.href.startsWith("https://avatarx.live")) {
      datadogRum.init({
        applicationId: "dbaa2039-c8bd-4987-bc11-54fb5d439c61",
        clientToken: "pub2fa0567f4303efa5c2d39aaa30ae6861",
        site: "us5.datadoghq.com",
        service: "avatarx",
        env: process.env.NEXT_PUBLIC_ENV, // Use a public environment variable
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });
  
      datadogRum.startSessionReplayRecording();
    }
  }, []);
  

  const initializeDatadog = (user_id) => {
        // log only to prod
    if(window.location.href.startsWith("https://avatarx.live")) {
      const user_email = localStorage.getItem("user_email");
      const user_first_name = localStorage.getItem("user_first_name")
      const user_last_name = localStorage.getItem("user_last_name")
  
        datadogRum.setUser({
          id: user_id,
          name: `${user_first_name} ${user_last_name}`,
          email: user_email,
        });
    }
  };

  const validPaths = [
    "/terms-and-conditions",
    "/",
    "/usage",
    "/subscriptions",
    "/subscriptions/add-billing-method",
    "/subscriptions/select-billing-method",
    "/chat",
  ]

  const handleLogin = () => {
    setShowHideDialog(true)
    setBuyCoinDialog(false)
    setBillingMethodDialog(false)
  }

  useEffect(() => {
    const user_id = localStorage.getItem("user_id")
    // only run this function when user is not logged in
    if(user_id){
      initializeDatadog(user_id)
    }
  }, [])

  if (pathname === "/*") return <EmptyHeader />

  return (
    <div onMouseLeave={() => setSidebarVisible(false)}>
      {/* xl:px-16 2xl:pr-3 2xl:pl-0 xl:pr-10 pr-0 xl:pl-11 md:pr-8 lg:pr-4 px-4 mx-auto 2xl:px-0 */}
      <header className="w-full z-[2001] py-3 fixed top-0 left-0 bg-white shadow-sm dark:bg-gray-900 ">
        {isBuyCoinDialgOpen && (
          <BuyCoinDialog
            balance={headerCredit}
            setBuyCoinDialog={setBuyCoinDialogOpen}
          />
        )}
        <div className="px-4 dark:text-white">
          <div className="flex items-center">
            <button
              onClick={toggleSidebar}
              className={`rounded-md px-1 mr-3 transition dark:bg-gray-900  ${
                sidebarVisible ? "text-white bg-pink-600" : "bg-gray-100"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            {/* =========== Left Side Logo ============= */}
            <Link
              href="/"
              className="md:w-36 w-24"
              onClick={() => setSidebarVisible(false)}
            >
              <Image src={logoSrc} alt="avatar X" width={120} height={50} />
            </Link>

            {/* <div className="2xl:flex xl:flex hidden items-center gap-2">
            <Link href="/">
              <button
                type="button"
                className={`size-12 border-none outline-none flex justify-center items-center rounded-full ${
                  pathname === "/" ? "bg-white drop-shadow-xl" : ""
                } `}
              >
                <Image src="/assets/svg/one.svg" alt="One" width={28} height={28} />
              </button>
            </Link>
            <Link href="/chat">
              <button
                type="button"
                className={`size-12 border-none outline-none flex justify-center items-center rounded-full ${
                  pathname === "/chat" ? "bg-white drop-shadow-xl" : ""
                } `}
              >
                <Image src="/assets/svg/message.svg" alt="Message" width={28} height={28} />
              </button>
            </Link>
          </div> */}
            {/* =========== Right Side Logo ============= */}
            <div className="ml-auto">
              {loggedIn ? (
                <div className="flex items-center gap-3 lg:gap-5">
                  {/* <div onClick={() => setBuyCoinDialog(true)} className="w-[101px] h-[26px] cursor-pointer px-[6.74px] py-[3.37px] bg-neutral-600/40 relative rounded-tr-[60px] rounded-br-[60px] backdrop-blur-sm flex-col justify-center items-end gap-[11.24px] inline-flex">
                <div className="justify-start items-center inline-flex">
                  <Image
                    src="/assets/images/coin.png"
                    className="absolute -top-[17px] -left-5"
                    alt="Profile"
                    width={49}
                    height={50}
                  />
                  <div className="text-white text-xs font-medium relative -left-1 leading-[49.45px]">
                    400 coins
                  </div>
                </div>
              </div> */}

                  {/* <button className="p-1 rounded-full border border-gray dark:border-gray-700 focus:outline-none hover:bg-pink-600 hover:text-white hidden lg:block px-3"
                onClick={()=>setBuyCoinDialog(true)}
              >
               {availableCoins} Credits Remaining
              </button> */}

                  {/*  My own credit UI */}
                  <button
                    onClick={() => setBuyCoinDialogOpen(true)}
                    className="p-1 rounded-full border border-gray dark:border-gray-700 focus:outline-none hover:bg-pink-600 hover:text-white px-3"
                  >
                    <span className="text-sm">
                      <strong>{headerCredit}</strong> Credits
                    </span>
                  </button>

                  <button
                    onClick={toggleTheme}
                    className="p-1 rounded-full border border-gray dark:border-gray-700 focus:outline-none hover:bg-pink-600 hidden lg:block px-2"
                  >
                    {theme === "light" ? "🌙" : "☀️"}
                  </button>

                  <div className="relative cursor-default">
                    <div
                      onClick={() =>
                        setProfileDropDownMenu(!profileDropDownMenu)
                      }
                      className="flex gap-2 cursor-pointer justify-center items-center"
                    >
                      <div className="size-9 bg-gradient-to-b from-neutral-400 to-pink-500 rounded-full shadow flex-col justify-center items-center gap-1 flex">
                        <Image
                          src="/assets/svg/profile-white.svg"
                          alt="Profile"
                          width={15}
                          height={15}
                        />
                      </div>
                      {/* <span className='text-indigo-950 md:text-base text-sm select-none font-semibold capitalize dark:text-white'>
                        {localStorage.getItem("user_first_name")}
                      </span> */}
                      {profileDropDownMenu ? (
                        <Image
                          src="/assets/svg/arrow-up.svg"
                          alt="Profile"
                          width={15}
                          height={15}
                        />
                      ) : (
                        <Image
                          src="/assets/svg/arrow-down.svg"
                          alt="Profile"
                          width={15}
                          height={15}
                        />
                      )}
                    </div>
                  </div>
                  {profileDropDownMenu && (
                    <>
                      <div className="w-64 absolute right-2 top-full mt-5 rounded-lg bg-white shadow dark:fill-white">
                        <div class="w-0 h-0 z-10 absolute -top-5 right-2 border-l-[30px] border-l-transparent border-b-[40px] border-b-white border-r-[30px] border-r-transparent"></div>
                        <ul className="flex flex-col z-50 relative">
                          {/* <li
                        onClick={() => {
                          router.push("/usage")
                          setProfileDropDownMenu(false)
                        }}
                        className="flex items-center px-5 py-3.5 group text-indigo-950 hover:bg-gradient-to-b hover:text-white from-neutral-400 to-pink-500 text-base font-medium cursor-pointer gap-2"
                      >
                        <Image src="/assets/svg/cog.svg" alt="Profile" className="group-hover:invert" width={25} height={25} />
                        Settings
                      </li>
                      <li
                        onClick={() => {
                          router.push("/subscriptions")
                          setProfileDropDownMenu(false)
                        }}
                        className="flex items-center px-5 py-3.5 hover:bg-gradient-to-b hover:text-white from-neutral-400 to-pink-500 text-indigo-950 text-base font-medium cursor-pointer gap-2"
                      >
                        <Image src="/assets/svg/fluent_premium-32-regular.svg" alt="Profile" width={25} height={25} />
                        Subscription
                      </li> */}
                          <li className="lg:hidden flex group items-center px-5 py-3.5 rounded-t-lg text-indigo-950  text-base font-medium cursor-default border-b gap-2 overflow-hidden">
                            {localStorage.getItem("user_first_name")}
                          </li>
                          <li
                            onClick={() => {
                              localStorage.clear()
                              setLoggedIn(false)
                              window.location.reload()
                            }}
                            className="flex group items-center px-5 py-3.5 rounded-b-lg text-indigo-950 hover:bg-pink-500 hover:text-white transition-all  text-base font-medium cursor-pointer gap-2 overflow-hidden"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Icon/Outline/logout">
                                <path
                                  id="Vector"
                                  d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                            Logout
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="flex items-center cursor-pointer sm:gap-5 gap-3">
                  <button
                    onClick={toggleTheme}
                    className="px-2 lg:px-1 py-1 rounded-full border border-pink-500 dark:border-gray-700 focus:outline-none hover:bg-pink-600  transition-colors "
                  >
                    {theme === "light" ? (
                      <span className="text-pink-500 text-sm transform -scale-x-10 lg:px-2">
                        🌙
                      </span>
                    ) : (
                      <span className="lg:px-2">☀️</span>
                    )}
                  </button>
                  {/* <div onClick={() => setBuyCoinDialog(true)} className="w-[87px] h-[21px] px-[6.74px] py-[3.37px] bg-neutral-600/40 relative rounded-tr-[60px] rounded-br-[60px] backdrop-blur-sm flex-col justify-center items-end gap-[11.24px] inline-flex">
                <div className="justify-start items-center inline-flex">
                  <Image
                    src="/assets/images/coin.png"
                    className="absolute -top-[17px] -left-5"
                    alt="Profile"
                    width={49}
                    height={50}
                  />
                  <div className="text-white text-xs font-medium relative -left-1 leading-[49.45px]">
                    0 coins
                  </div>
                </div>
              </div> */}

                  <div
                    onClick={handleLogin}
                    id="login"
                    className="lg:px-10 px-1 group hover:bg-pink-600 hover:border-pink-600 transition lg:py-3 py-1 border border-pink-500 lg:rounded-[60px] rounded-full shadow flex-col justify-center items-center gap-1 inline-flex"
                  >
                    <div className="text-pink-500 group-hover:text-white sm:text-sm text-xs font-bold leading-tight hidden lg:block ">
                      Login
                    </div>
                    <svg
                      color="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="22px"
                      height="22px"
                      className="fill-current lg:hidden group-hover:text-white dark:text-white"
                    >
                      <path d="M19.5,18V6A1.50164,1.50164,0,0,0,18,4.5H16a.5.5,0,0,1,0-1h2A2.50263,2.50263,0,0,1,20.5,6V18A2.50263,2.50263,0,0,1,18,20.5H16a.5.5,0,0,1,0-1h2A1.50164,1.50164,0,0,0,19.5,18ZM4,12.5h8.793L8.64648,16.64648a.5.5,0,1,0,.707.707l5-5a.49983.49983,0,0,0,0-.707l-5-5a.5.5,0,0,0-.707.707L12.793,11.5H4a.5.5,0,0,0,0,1Z" />
                    </svg>
                  </div>

                  <div
                    onClick={() => {
                      setShowHideDialog(true)
                      setBuyCoinDialog(false)
                      setBillingMethodDialog(false)
                    }}
                    className="sm:px-5 lg:px-4 px-3 hover:bg-pink-600 transition py-3 bg-pink-500 rounded-[60px] shadow sm:space-x-2 space-x-1 justify-center items-center gap-1 flex text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="sm:size-5 size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                      />
                    </svg>

                    <div className="text-white hidden lg:block sm:text-sm text-xs font-bold leading-tight">
                      Free Trial
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {showHideDialog && (
        <DiscoverDialog
          setShowHideDialog={setShowHideDialog}
          showEmailDialog={showEmailDialog}
          setShowEmailDialog={setShowEmailDialog}
        />
      )}
      {/* <DiscoverDialog setShowHideDialog={setShowHideDialog} /> */}
      {showEmailDialog && (
        <OpenEmailDialog setShowEmailDialog={setShowEmailDialog} />
      )}
      {buyCoinDialog && (
        <BuyCoinDialog
          setBuyCoinDialog={setBuyCoinDialog}
          setBillingMethodDialog={setBillingMethodDialog}
          balance={headerCredit}
        />
      )}
      {billingMethodDialog && (
        <BillingMethodDialog setBillingMethodDialog={setBillingMethodDialog} />
      )}
      {/* <Sidebar /> */}
    </div>
  )
}

const EmptyHeader = () => {
  return (
    <>
      <header className="w-full 2xl:max-w-screen-2xl xl:max-w-screen-2xl xl:px-16 2xl:pr-3 2xl:pl-0 xl:pr-10 pr-0 xl:pl-11 md:pr-8 lg:pr-4 px-4 mx-auto 2xl:px-0 z-50 pt-4 relative">
        {/* =========== Left Side Logo ============= */}
        <Link href="/">
          <Image
            src="/assets/images/logo.png"
            className="relative left-2.5"
            alt="FulLogo_Transparent"
            width={154}
            height={48}
          />
        </Link>
      </header>
    </>
  )
}

export { Header, EmptyHeader }
